/*global state*/
var toGlobalStateContainer = {
    ROOT: theone.url(''),
    SEARCH: {
        texts: {
            loading: Lang.get('app.loading')+"&#8230;",
            places: Lang.get('app.places'),
            people: Lang.get('app.search_people'),
            expertise: Lang.get('app.search_expertise'),
            tags: Lang.get('app.search_tags'),
            articles: Lang.get('app.articles'),
            expertgroups: Lang.get('app.learn_from'),
            tagline: Lang.get('app.i_theone'),
        },
        selected_expert_certificate_id: theone.selected_expert_certificate_id,
    },
    MAP: {
        address: theone.session ? theone.session.place : '',
    },
    TAGMANAGER: {
        errors: {
            GENERIC: Lang.get('expertise.tag_error_generic'),
            LIMIT: Lang.get('expertise.tag_error_limit'),
            WORDS: Lang.get('expertise.tag_error_length', {words : theone.App_UserExpertise_MAX_TAG_WORDS, chars : theone.App_UserExpertise_MAX_TAG_CHARS}),
            CHARACTERS: Lang.get('expertise.tag_error_length', {words : theone.App_UserExpertise_MAX_TAG_WORDS, chars : theone.App_UserExpertise_MAX_TAG_CHARS}),
        },
        labels: {
            addTag: Lang.get('settings.tag_select_and_add'),
            tagType: Lang.get('settings.tag_type'),
            addAnother: Lang.get('settings.tag_add_another'),
            helpPrefix: Lang.get('expertise.help_somebody_subtitle'),
        },
        maxTags: parseInt(theone.App_UserExpertise_MAX_TAGS_PER_CATEGORY),
    },
    LOGIN: {},
    CSRF: theone.csrf_token,
};

var LOGGING = Config.get('app.app_js_log');

function logger() {
    if (!LOGGING) return;
    if ( window.console && window.console.log ) {
        for (i in arguments) console.log(arguments[i]);
    }
}


/*
 * GLOBAL functions to be used by any elements
 */

function makeJoinCall(tourProposalId, userId, userName, userImg) {
    if (typeof toCallHandler !== "undefined") {
        toCallHandler.placeOutgoingCall( { tourProposalId: tourProposalId, userId: userId, userName: userName, userImg: userImg } );
    }
}
function makeInstantCall(userId, userName, userImg) {
    if (typeof toCallHandler !== "undefined") {
        toCallHandler.placeOutgoingCall( { userId: userId, userName: userName, userImg: userImg } );
    }
}
function makeOutgoingCall(userId, userImg, firstName, lastName, location, profession, tagline, rating, callbackText) {
    if (typeof toCallHandler !== "undefined") {
        toCallHandler.placeOutgoingCall( { userId: userId, userName: firstName, userImg: userImg, firstName: firstName, lastName: lastName, location: location, profession: profession, tagline: tagline, rating: rating, callbackText: callbackText } );
    }
}
function makeOutgoingTourCall(tourProposalId, userId, userImg, firstName, lastName, location, profession, tagline, rating) {
    if (typeof toCallHandler !== "undefined") {
        toCallHandler.placeOutgoingCall( { userId: userId, userName: firstName, userImg: userImg, firstName: firstName, lastName: lastName, location: location, profession: profession, tagline: tagline, rating: rating, tourProposalId: tourProposalId } );
    }
}
function makeOutgoingPromoCall(userId, userImg, firstName, lastName, location, profession, tagline, rating, callbackText, promo) {
    if (typeof toCallHandler !== "undefined") {
        toCallHandler.placeOutgoingCall( { xmas: true, userId: userId, userName: firstName, userImg: userImg, firstName: firstName, lastName: lastName, location: location, profession: profession, tagline: tagline, rating: rating, promo:promo} );
    }
}

/*
 * showing modals per session data
 */

$(function() {

    function loadHandler(e) {
        $(this).unbind('mouseover',loadHandler);

        var menuUrl = theone.url("/ajax/menu?menu_id=" + $(this).closest('[data-menu-id]').data('menu-id') + "&menu_item_id=" + $(this).data('id'));

        var element = $(this);
        $.get( menuUrl, function( data ) {
            var html = $.parseHTML(data.trim());
            var toAppend = $(html).find('> li > ul');
            element.append(toAppend);
        });
    }
    $('li.experts.deferred > ul > li[data-id]').on('mouseover',loadHandler);

    if (theone.session && theone.session.modal && theone.session.modal == "register" || (theone.old && (theone.old.step || theone.session.step)) ) {
        if (theone.old.step && ( theone.errors && theone.old.step == 3) || (theone.errors == undefined && theone.old.step == 1) ) {
            $("#register-user-details").modal("show");
        } else if (theone.session.registration_confirm_sent) {
            $("#register-user-emailconfirm").modal("show");
        } else if (theone.session.registration_confirmed && theone.onboardingTags == undefined) {
            $("#register-user-onboarding").modal("show");
        } else if (theone.session.email) {
            CTOAjaxModalHandler.loadModal(theone.url("/ajax/register-user"), function () {
                if (typeof toSignupStartHandler !== "undefined") {
                    toSignupStartHandler.afterInit(function () {
                        toSignupStartHandler.showEmailSignup(theone.session.email);
                        /* check for invitation */
                        if (theone.session.invitation_certificate) {
                            toSignupStartHandler.showSignupCertified(theone.session.invitation_certificate);
                        }
                    });
                }
            });
        } else if (theone.session.register_message) {
            CTOAjaxModalHandler.loadModal(theone.url("/ajax/register-user"), function () {
                var msg = theone.session.register_message; //quotes unescape?
                if (msg && typeof toSignupStartHandler !== "undefined") {
                    toSignupStartHandler.showNotificationMessage("modal-notification-content-start-form", msg);
                }
            });
        } else if (theone.old.step && theone.errors && theone.old.step === 1) {
            CTOAjaxModalHandler.loadModal(theone.url("/ajax/register-user"));
        } else if (theone.session.modal && theone.session.modal === "register") {
            CTOAjaxModalHandler.loadModal(theone.url("/ajax/register-user"));
        } else {
            console.log("unhandled step");
        }
    }
    if (theone.session && theone.session.registration_simple_confirm_sent) {
        // same modal id (but different content)
        $("#register-user-emailconfirm").modal("show");
    }
    if (theone.session && theone.session.signup_simple_success) {
        $("#register-simple-success").modal("show");
    }
    if (theone.session && theone.session.signup_simple_facebook && theone.session.simple_user_data) {
        // only load the fb signup popover on the home page
        //if (window.location.pathname === "/" + window.theone.session.content_locale)
        // or just prevent it loading on the terms page? 
        if (window.location.pathname.indexOf("/info/toc") === -1) {
            CTOAjaxModalHandler.loadModal(theone.url("/ajax/register-user"), function () {
                /* // this is unnecessary - toSignupStartHandler.init / initErrors should handle the same thing
                if (typeof toSignupStartHandler !== "undefined") {
                    toSignupStartHandler.afterInit(function () {
                        toSignupStartHandler.fillAndShowSignupSimple(theone.session.simple_user_data.email);
                    });
                }
                */
            });
        }
    }
    if (theone.session && theone.session.modal && theone.session.modal == "login" || (theone.old && theone.old.type && theone.old.type == "login")) {
        CTOAjaxModalHandler.loadModal(theone.url("/ajax/login-user"), function () {
            if (theone.session.email) {
                if (typeof toLoginHandler !== "undefined") {
                    toLoginHandler.setInitialEmailAndShow(theone.session.email);
                }
            }
        });
    }
    if (theone.session && theone.session.modal && theone.session.modal == "password" || (theone.old && theone.old.type && theone.old.type == "password")) {
        $("#password-user").modal("show");
    }
    if (theone.user == undefined && (theone.errors && theone.errors.email) && (theone.old && theone.old.step == undefined)) {
        CTOAjaxModalHandler.loadModal(theone.url("/ajax/login-user"));
    }
    if (theone.session && ( theone.session.onboarding_expertise_saved
        || (theone.session.registration_confirmed && theone.onboardingTags) /* this one is not used anymore? TODO CHECK */
        || (theone.session.invite_certificate_accepted && theone.onboardingTags))) {
        $("#register-user-onboarding-tags").modal("show");
    }
    if (theone.session && theone.session.modal && theone.session.modal === "invited_certificate_user") {
        if (typeof toCertificateRulesHandler !== "undefined") {
            toCertificateRulesHandler.initAndOpen();
        }
    }
    if (theone.session && theone.session.modal && theone.session.modal === "register-certified") {
        if (typeof CTOSignupSelfInviteHandler !== "undefined") {
            CTOSignupSelfInviteHandler.callInitAndShow();
        }
    }
    if (theone.session && theone.session.registration_confirmed) {
        if (typeof gtag === "function") {
            gtag("event", "conversion", {"send_to": "AW-857453172/rQcGCNvL7IwBEPTk7pgD"});
        }
        if (typeof fbq === "function") {
            fbq("track", "CompleteRegistration");
        }
    }

});
    
