/*
 * custom js - is already included in gulp so just use this for generic js helpers
 */

function handleTOAction() {
    // we can use this instead of $.noop() for better user experience
}
function handleLookalongAction() {
    // we can use this instead of $.noop() for better user experience
}

/*
 * CTOGoogleIsLoaded - google api load callback, see head.blade
 */
var CTOGoogleIsLoaded = function () {
    toGenericHelper.googleLoaded();
}

var toModalHelper = {
    showConfirmInput: function(content,title,onConfirm,onCancel,buttonYes,buttonNo,formContent) {
        if (buttonYes == undefined || buttonYes == '' || buttonYes.length == 0) buttonYes = Lang.get('app.ok');
        if (buttonNo == undefined ||  buttonNo == ''|| buttonNo.length == 0) buttonNo = Lang.get('app.cancel');
        var modalId = "mid_" + (new Date()).getTime();
        var modal = '' +
            '<div class="modal fade" data-vesion="2.0" id="' + modalId +'">' +
              '<div class="modal-dialog">' +
                '<div class="modal-content">' +
                    (title ? '<div class="modal-header"><h4 class="modal-title">' + title + '</h4></div>' : '') +
                  '<div class="modal-body">' +
                    content +
                  '<div><textarea name="confirm-input" placeholder="Type here..."></textarea></div>' +
                  '</div>' +
                  '<div class="modal-footer">' +
                    '<button type="button" class="btn btn-primary confirm-button" data-dismiss="modal">' + buttonYes + '</button>' +
                    '<button type="button" class="btn btn-primary cancel-button" data-dismiss="modal">' + buttonNo + '</button>' +
                  '</div>' +
                '</div>' +
              '</div>' +
            '</div>';

        $('body').append(modal);
        $("#" + modalId).modal();
        $("#" + modalId).on('hidden.bs.modal', function () {
            $("#" + modalId).remove();
        })
        $("#" + modalId + " .confirm-button").on('click',function(e) {
            console.log('on ok');
            if (onConfirm != undefined && (typeof onConfirm == "function")) onConfirm($('#' + modalId + ' textarea[name="confirm-input"]').val());
        });
        $("#" + modalId + " .cancel-button").on('click',function(e) {
            console.log('on cancel');
            if (onCancel != undefined && (typeof onCancel == "function")) onCancel();
        });
    },
    showConfirm: function(content,title,onConfirm,onCancel,buttonYes,buttonNo) {  
        if (buttonYes == undefined || buttonYes == '' || buttonYes.length == 0) buttonYes = Lang.get('app.ok');
        if (buttonNo == undefined ||  buttonNo == ''|| buttonNo.length == 0) buttonNo = Lang.get('app.cancel');
        var modalId = "mid_" + (new Date()).getTime();
        var modal = '' +
            '<div class="modal fade" data-vesion="2.0" id="' + modalId +'">' +
              '<div class="modal-dialog">' +
                '<div class="modal-content">' +
                    (title ? '<div class="modal-header"><h4 class="modal-title">' + title + '</h4></div>' : '') +
                  '<div class="modal-body">' +
                    content +
                  '</div>' +
                  '<div class="modal-footer">' +
                    '<button type="button" class="btn btn-primary confirm-button" data-dismiss="modal">' + buttonYes + '</button>' +
                    '<button type="button" class="btn btn-primary cancel-button" data-dismiss="modal">' + buttonNo + '</button>' +
                  '</div>' +
                '</div>' +
              '</div>' +
            '</div>';

        $('body').append(modal);
        $("#" + modalId).modal();
        $("#" + modalId).on('hidden.bs.modal', function () {
            $("#" + modalId).remove();
        })
        $("#" + modalId + " .confirm-button").on('click',function(e) {
            console.log('on ok');
            if (onConfirm != undefined && (typeof onConfirm == "function")) onConfirm();
        });
        $("#" + modalId + " .cancel-button").on('click',function(e) {
            console.log('on cancel');
            if (onCancel != undefined && (typeof onCancel == "function")) onCancel();
        });
    },
    showAlert: function(content,title,onDone,buttonDismiss) {        
        if (buttonDismiss == undefined || buttonDismiss == '' || buttonDismiss.length == 0) buttonDismiss = Lang.get('app.ok');
        var modalId = "mid_" + (new Date()).getTime();
        var modal = '' +
            '<div class="modal fade" data-vesion="2.0" id="' + modalId +'">' +
              '<div class="modal-dialog">' +
                '<div class="modal-content">' +
                    (title ? '<div class="modal-header"><h4 class="modal-title">' + title + '</h4></div>' : '') +
                  '<div class="modal-body">' +
                    content +
                  '</div>' +
                  '<div class="modal-footer">' +
                    '<button type="button" class="btn btn-primary confirm-button" data-dismiss="modal">' + buttonDismiss + '</button>' +
                  '</div>' +
                '</div>' +
              '</div>' +
            '</div>';

        $('body').append(modal);
        $("#" + modalId).modal();
        $("#" + modalId).on('hidden.bs.modal', function () {
          $("#" + modalId).remove();
        })
        $("#" + modalId + " .confirm-button").on('click',function(e) {
            if (onDone != undefined && (typeof onDone == "function")) onDone();
        });
    }
}

/*
 * ============================= GenericHelper - other js helper functions =============================
 */

var toGenericHelper = {
    init: function (params) {
        //console.log("toGenericHelper.init()", params);
        this.initElements();
        // doublecheck session state here
        this.confirmSessionState();
    },
    initElements: function () {
        var _this = this;
        $(document).ready(function () {
            _this.initDomReady();
        });
        $(window).on("load", function() {
            // NOTE: this has to be window.onload, dom ready is not enough, because has to wait for bootstrap.js too
            // ---> TODO it might be better to break up the compiled.js to assets.js + theone.js
            //           because now jQuery is in compiled, bootstrap.js can not precede compiled.js
            _this.initModals();
        });
    },
    initDomReady: function () {
        this._isDomReady = true;
        this.initGoogleMaps();
        this.initVisualCandy();
        this.initStarRatings();
        this.initArticleReadCounter();
        this.initExpertGroupReadCounter();
        this.initWebinarViewedCounter();
        this.initLanguageChange();
        this.initLinkConfirm();
        this.initCtaJoinButton();
    },
    hasGetParam: function (key) {
        var query = document.location.search.substr(1);
        if (query && query.length) {
            var data = query.split("&");
            for (var x in data) {
                var action = data[x].split("=");
                if (action[0] === key) {
                    return true;
                }
            }
        }
    },
    parseGet: function () {
        var query = document.location.search.substr(1);
        if (query && query.length) {
            var data = query.split("&");
            for (var x in data) {
                var action = data[x].split("=");
                //this.parseAction(action);
            }
        }
    },
    parseHash: function () {
        var hash = document.location.hash.substr(1);
        if (hash && hash.length) {
            var data = hash.split(",");
            for (var x in data) {
                var action = data[x].split("|");
                //this.parseAction(action);
            }
        }
    },
    confirmSessionState: function () {
        /* // the local cache issue was solved by adding max-age to response headers for cached htmls
        setTimeout(function () {
            $.ajax({
                method: "POST",
                url: theone.url("/api/confirm-session-state"),
                data: {},
            }).done(function (res) {
                if (res.success) {
                    //console.log("confirmSessionState success", res);
                    if (res.uid && !theone.user) {
                        location.reload();
                    }
                } else {
                    console.log("confirmSessionState ERROR", res);
                }
            }).fail(function (xhr, status) {
                console.log("confirmSessionState request error!", status);
            });
        }, 200);
        */
    },
    /*
     * DOM related, but still generic stuff below
     */
    showLoader: function (element) {
        if (element == undefined) element = $("#app-layout");
        if (document.getElementById("overlayloader") == undefined) {
            element.prepend('<div id="overlayloader" class="loading">' + toGlobalStateContainer.SEARCH.texts.loading + '</div>');
        }
    },
    hideLoader: function () {
        $("#overlayloader").remove();
    },
    initLinkConfirm: function() {
        $('input[type="submit"][data-confirm]').on('click',function(e) {
            var form = $(this).closest("form");
            e.preventDefault();
            toModalHelper.showConfirm($(this).data("confirm"), $(this).data("confirm-title"), function () {
                form.submit();
            }, undefined, $(this).data("confirm-yes"), $(this).data("confirm-no"));
        });
        $("a[data-confirm]").on("click", function (e) {
            var url = $(this).attr("href");
            e.preventDefault();
            toModalHelper.showConfirm($(this).data("confirm"), $(this).data("confirm-title"), function () {
                document.location.href = url;
            }, undefined, $(this).data("confirm-yes"), $(this).data("confirm-no"));
        });
    },
    initLanguageChange: function() {
        //console.log("initLanguageChange");
        $("#language-select a").on("click", function (e) {
            e.preventDefault();
            var languageOptions = $(this).closest("li").prop("classList");
            var url = $(this).attr("href");
            var urlOption;
            for (var i = 0; i < languageOptions.length; i++) {
                urlOption = $('link[hreflang="' + languageOptions[i] + '"]').attr("href");
                if (urlOption != undefined) {
                    url = urlOption;
                    break;
                }
            }
            document.location.href = url;
        });
    },
    initVisualCandy: function() {
        //make buttons to scrollable content
        $("[data-scroll-target]").on("click", function () {
            var scrollPosition = $($(this).data("scroll-target")).offset().top;
            $("html, body").animate({
                scrollTop: scrollPosition
            });
        });
    },
    initStarRatings: function () {
        //console.log("toGenericHelper.initStarRatings", $(".star-rating").length);
        $(".star-rating").each(function (i, element) {
            var starDomElement = $(this);
            var isInteractive = !!starDomElement.data("interactive");
            var isArticle = !!starDomElement.data("article-id");
            var normalFill = "#DADADA";
            var ratedFill = "#333333";
            var maxVal = 10;
            if (isArticle && theone.App_Blog_Article_RATING_SCALE) {
                maxVal = theone.App_Blog_Article_RATING_SCALE;
            }
            if ($(element).data("normal-fill")) normalFill = $(element).data("normal-fill");
            if ($(element).data("rated-fill")) ratedFill = $(element).data("rated-fill");
            starDomElement.rateYo({
                starWidth: starDomElement.data("rating-width"),
                rating: starDomElement.data("rating-value"),
                normalFill: normalFill,
                ratedFill: ratedFill,
                maxValue: maxVal,
                readOnly: !isInteractive,
                precision: isArticle ? 0 : 1,
                spacing: "-2px"
            });
            if (isInteractive && isArticle) {
                starDomElement.on("click", function () {
                    var rateVal = $(this).rateYo("rating");
                    var aid = $(this).data("article-id");
                    //console.log("Rate article", rateVal, aid);
                    $.ajax({
                        method: "POST",
                        url: theone.url("/api/rate-blog-article"),
                        data: { blog_article_id: aid, rating: rateVal },
                        dataType: "json"
                    }).done(function (res) {
                        if (res.success) {
                            console.log("RATED", res);
                        } else {
                            console.log("ERROR", res);
                        }
                    }).fail(function (xhr, status) {
                        console.log("Rating request error!", status);
                    }).always(function () {
                        starDomElement.rateYo("option", "readOnly", true);
                    });
                });
            }
        });
    },
    initArticleReadCounter: function () {
        var _this = this;
        var article = $("#article");
        var articleId = article.data('article-id');
        if (articleId == undefined || articleId <= 0) return;
        console.log("initArticleReadCounter()", articleId);

        _this.trackArticleRead(articleId);

        var readMoreCounted = false;

        var checkScroll = function() {
            if (!readMoreCounted && _this.bottomIsInViewPort(article)) {
                readMoreCounted = true;
                _this.trackArticleRead(articleId,true);
                $(window).off('resize scroll',checkScroll);
            }
        };
        $(window).on('resize scroll', checkScroll);
    },
    trackArticleRead: function(articleId,readMore) {
        var apiURL = readMore ? "/api/count-article-read-more" : "/api/count-article-read";
        $.ajax({
            method: "POST",
            dataType: "json",
            contentType: "application/json",
            processData: false,                    
            url: theone.url(apiURL),
            data: JSON.stringify({ article_id : articleId}),
        }).done(function (res) {
            if (res.success) {
                console.log("trackArticleRead() READ", apiURL, res);
            } else {
                console.log("trackArticleRead() ERROR", apiURL, res);
            }
        }).fail(function (xhr, status) {
            console.log("trackArticleRead() Article countrequest error!", apiURL, status);
        });
    },
    trackCtaAction: function (actionElement) {
        var boxId = $(actionElement).closest(".cta_box").data("tid");
        var postData = {
            _token: theone.csrf_token,
            action: "join",
            boxId: boxId
        };
        $.ajax({
            method: "POST",
            url: theone.url("/api/cta-track-action"),
            data: postData
        }).done(function (data) {
            console.log("trackCtaAction done: " , data);
        }).fail(function (xhr, status) {
            console.log("trackCtaAction failed: " , status);
        });
    },
    handleCtaJoinClick: function (btn) {
        if (theone.user) {
            // already logged in
            console.log("user already joined");
        } else {
            if ($(".btn-register").length) {
                $(".btn-register").trigger("click");
                this.trackJoinClick(btn);
            } else {
                //...
            }
        }
    },
    trackJoinClick: function (btn) {
        var ctaBoxSelector = ".cta_box"; // maybe this should come from the CtaBox class?
        var boxId = $(btn).closest(ctaBoxSelector).data("tid");
        var postData = {
            _token: theone.csrf_token,
            action: "join",
            boxId: boxId
        };
        $.ajax({
            method: "POST",
            url: theone.url("/api/cta-track-action"),
            data: postData
        }).done(function (data) {
            console.log("trackJoinClick done: " , data);
        }).fail(function (xhr, status) {
            console.log("trackJoinClick failed: " , status);
        }).always(function () {
            //...
        });
    },
    initCtaJoinButton: function () {
        var _this = this;
        var selector = "." + theone.App_Models_CallToAction_CtaBox_VIEW_JS_JOIN_BTN_CLASS;
        if (selector) {
            $(selector).on("click", function () {
                _this.handleCtaJoinClick(this);
            });
        }
    },
    initWebinarViewedCounter: function () {
        var _this = this;
        var webinar = $("#webinar");
        var webinarId = webinar.data('webinar-id');
        if (webinarId == undefined || webinarId <= 0) return;
        console.log("initWebinarReadCounter()", webinarId);

        _this.trackWebinarViewed(webinarId);
    },
    trackWebinarViewed: function(webinarId) {
        var apiURL = "/api/count-webinar-viewed";
        $.ajax({
            method: "POST",
            dataType: "json",
            contentType: "application/json",
            processData: false,                    
            url: theone.url(apiURL),
            data: JSON.stringify({ webinar_id : webinarId}),
        }).done(function (res) {
            if (res.success) {
                console.log("trackWebinarViewed() READ", apiURL, res);
            } else {
                console.log("trackWebinarViewed() ERROR", apiURL, res);
            }
        }).fail(function (xhr, status) {
            console.log("trackWebinarViewed() Webinar countrequest error!", apiURL, status);
        });
    },
    initExpertGroupReadCounter: function () {
        var _this = this;
        var expertGroup = $("#expert-group");
        var expertGroupId = expertGroup.data('expert-group-id');
        if (expertGroupId == undefined || expertGroupId <= 0) return;
        console.log("initExpertGroupReadCounter()", expertGroupId);

        _this.trackExpertGroupRead(expertGroupId);
    },
    trackExpertGroupRead: function(expertGroupId) {
        var apiURL = "/api/count-expert-group-read";
        $.ajax({
            method: "POST",
            dataType: "json",
            contentType: "application/json",
            processData: false,                    
            url: theone.url(apiURL),
            data: JSON.stringify({ expert_group_id : expertGroupId}),
        }).done(function (res) {
            if (res.success) {
                console.log("trackExpertGroupRead() READ", apiURL, res);
            } else {
                console.log("trackExpertGroupRead() ERROR", apiURL, res);
            }
        }).fail(function (xhr, status) {
            console.log("trackExpertGroupRead() ExpertGroup countrequest error!", apiURL, status);
        });
    },
    bottomIsInViewPort: function(element) {
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom < viewportBottom;
    },
    initModals: function () {
        if ($("#modal-messages").length > 0) {
            $("#modal-messages").modal("show");
        }
        if ($("#modal-popover").length > 0) {
            $("#modal-popover").modal("show");        
        }        
        $(".modal").on("show.bs.modal", function(e) {
            $(".modal.in").modal("hide");
        });
    },
    googleLoaded: function () {
        //console.log("toGenericHelper.googleLoaded()");
        this.gmLoaded = true;
        this.initGoogleMaps();
        toAutocompleteHandler.checkInputsToInit();
        if (typeof toMapMarkerHandler !== "undefined") {
            toMapMarkerHandler.initAfterGoogle();
        }
    },
    initGoogleMaps: function () {
        if (this.gmLoaded && this._isDomReady) {
            this.initOtherGoogleStuff();
            // init map
            if (typeof toMapHandler !== "undefined") {
                toMapHandler.initMap();
                //toMapHandler.initNewRequestMap(); // will be called in the view
            }
            if (typeof toAutocompleteHandler !== "undefined") {
                toAutocompleteHandler.initAutocomplete();
            }
            // initSmartSearch
            if (typeof toSearchHandler !== "undefined") {
                toSearchHandler.init({ searchInputSelector: "input[name=search]" });
            }
        }
    },
    initOtherGoogleStuff: function () {
        //console.log("initOtherGoogleStuff()"); // this is kinda toMapHandler material?
        google.maps.Map.prototype.setCenterWithOffset = function(latlng, offsetX, offsetY) {
            var map = this;
            var ov = new google.maps.OverlayView();
            ov.onAdd = function() {
                var proj = this.getProjection();
                var aPoint = proj.fromLatLngToContainerPixel(latlng);
                aPoint.x = aPoint.x + offsetX;
                aPoint.y = aPoint.y + offsetY;
                map.panTo(proj.fromContainerPixelToLatLng(aPoint));
            };
            ov.draw = function() {};
            ov.setMap(this);
        };
        if (typeof toMapHandler !== "undefined") {
            toMapHandler.setGeoCoder(new google.maps.Geocoder());
        }
        this._gmInfoWindow = new google.maps.InfoWindow({maxWidth:320,disableAutoPan:true});
        google.maps.event.addListener(this._gmInfoWindow, "domready", this.customizeInfoWindow);
    },
    getGoogleMap: function (checkMap) {
        if (typeof toMapHandler !== "undefined") {
            return toMapHandler.getMap(checkMap);
        }
        return null;
    },
    customizeInfoWindow: function () {
        console.log("customizeInfoWindow()");
        var iwOuter = $(".gm-style-iw"); // Reference to the DIV that wraps the bottom of infowindow
        /* Since this div is in a position prior to .gm-div style-iw.
         * We use jQuery and create a iwBackground variable,
         * and took advantage of the existing reference .gm-style-iw for the previous div with .prev().
        */
        var iwBackground = iwOuter.prev();
        // Removes background shadow DIV
        iwBackground.children(":nth-child(2)").css({"display" : "none"});
        // Removes white background DIV
        iwBackground.children(":nth-child(4)").css({"display" : "none"});
        // Moves the infowindow 115px to the right.
        iwOuter.parent().parent().css({left: "170px", top: "10px"});
        // Moves the shadow of the arrow 76px to the left margin.
        iwBackground.children(":nth-child(3)").attr("style", function(i,s){ return s + "left: 15px !important;margin-top:-15px !important;transform:rotate(25deg);"});
        // Moves the arrow 76px to the left margin.
        iwBackground.children(":nth-child(1)").css("display", "none");
        // Changes the desired tail shadow color.
        iwBackground.children(":nth-child(3)").find("div").children().css({"box-shadow": "none"});
        // Reference to the div that groups the close button elements.
        var iwCloseBtn = iwOuter.next();
        // Apply the desired effect to the close button
        iwCloseBtn.css({opacity: "1", right: "50px", top: "20px"});
        // If the content of infowindow not exceed the set maximum height, then the gradient is removed.
        if ($(".iw-content").height() < 140) {
            $(".iw-bottom-gradient").css({display: "none"});
        }
        // The API automatically applies 0.7 opacity to the button after the mouseout event. This function reverses this event to the desired value.
        iwCloseBtn.mouseout(function() {
            $(this).css({opacity: "1"});
        });
    },
    toggleFormSection: function (part) {
        var button  = $("#" + part + "-button");
        var section = $("#" + part + "-section");
        var input   = $("#" + part + "_button_visible");
        var altText = button.data("alt-text");
        var buttonAlwaysVisible = button.data("always-visible");
        var originalText = button.data("original-text");
        var toggleWith = $("[data-toggle-with='" + part + "']");
        var toggleWithInverse = $("[data-toggle-with-inverse='" + part + "']");
        var inputs = section.find("input,select,textarea");
        if (button.data("visible")) {
            button.data("visible", 0);
            input.val(0);
            if (altText) {
                button.data("original-text", button.html());
                button.html(altText);
            }
            if (!buttonAlwaysVisible && !altText) {
                button.hide("slide", { direction: "left" }, 200);
                toggleWith.hide();
                toggleWithInverse.show();
            }
            section.slideDown(200);
            inputs.prop("disabled", false);
        } else {
            button.data("visible", 1);
            input.val(1);
            if (altText) {
                button.show("slide", { direction: "right" }, 200);
            } else {
                button.html(originalText);
            }
            toggleWith.show();
            toggleWithInverse.hide();
            section.slideUp(200);
            inputs.prop("disabled", true);
        }
        $(inputs).trigger("change");
    },
    isMobileLayout : function() {
        return $(window).width() <= 767;
    }
};

toGenericHelper.init(); // no need to be in document ready


/*
 * ============================= TemplateHandler -  =============================
 */
var TemplateHandler = {
    getTemplate: function (templateId,fields) {
        var selector = "script[type='text/template']#" + templateId;

        var template = $(selector).html();
        // console.log('getTemplate',templateId,selector,template);
        if (template != undefined && fields != undefined) {
            for( var key in fields ) {
                var value = fields[key];
                template = template.replace(new RegExp("#"+key+"#","g"), value);
            }
        }
        return template;
    },
};

/*
 * ============================= HighlightedExpertsLoader -  =============================
 */
var HighlightedExpertsLoader = {
    init: function (params) {
        var _this = this;
        var target = params.target;
        if ($('body').hasClass('article')) { 
            _this.getHighlightedUsers(params.articleId, function(result) {
                var experts = '';
                var expert;

                for (var i = 0; i < result.experts.length; i++) {
                    expert = result.experts[i];
                    if (expert.intro) {
                        expert.intro = TemplateHandler.getTemplate('highlighted-expert-intro',expert);
                    }
                    experts += TemplateHandler.getTemplate('highlighted-expert',expert);
                }            
                var highlightedExpertsTemplate = experts ? TemplateHandler.getTemplate('highlighted-experts',{experts:experts}) : '';
                $(target).html(highlightedExpertsTemplate);
            });
        }

        if ($('body').hasClass('profile')) { 
            _this.getRelatedUsers(params.userId,params.page,function(result) {
                var experts = '';
                var expert;

                if (result.experts.length == 0 ) {
                    $(target).remove();
                } else {
                console.log('so',target,$(target),result);
                    for (var i = 0; i < result.experts.length; i++) {
                        expert = result.experts[i];
                        experts += TemplateHandler.getTemplate('related-expert',expert);
                    }            
                    var title = result.title;
                    var highlightedExpertsTemplate = experts ? TemplateHandler.getTemplate('related-experts',{experts:experts,title:title}) : '';
                    $(target).html(highlightedExpertsTemplate);
                }

            });
        }



    }, 
    getHighlightedUsers: function(articleId,callBack) {

         $.ajax({
            contentType: "application/json",
            data: JSON.stringify({article_id:articleId}),
            dataType: "json",
            success: function(data) {
                if (data.success) {
                    logger('getHighlightedUsers',data);
                    callBack(data.data);
                } else {
                    logger(resultType + " ~ no success: " + data.error);
                }
            },
            error: function(){
                logger(resultType + " ERROR: connection");
            },
            processData: false,
            type: "POST",
            url: theone.url('/api/highlighted-users'),
        });
    },   
    getRelatedUsers: function(userId,page,callBack) {

         $.ajax({
            contentType: "application/json",
            data: JSON.stringify({page:page,user_id:userId}),
            dataType: "json",
            success: function(data) {
                if (data.success) {
                    logger('getRelatedUsers',data);
                    callBack(data.data);
                } else {
                    logger(resultType + " ~ no success: " + data.error);
                }
            },
            error: function(){
                logger(resultType + " ERROR: connection");
            },
            processData: false,
            type: "POST",
            url: theone.url('/api/related-users'),
        });
    },   
};

/*
 * ============================= ExpertGroupsScroller -  =============================
 */
var ExpertGroupsScroller = {

    scrollStop: 0,
    scrollStopEnd: 0,
    params:{},
    scrollStops: new Array(),
    init : function(params) {
        console.log('ExpertGroupsScroller.init()');
        var _this = this;            
        var items = $(params.holder).children();
        $("#expert-group-sidebar").addClass('fixed');
        // $("#expert-group-sidebar").css('transition','top 2s ease-in-out');
        console.log('ExpertGroupsScroller.init()',items);


        var curItem;

        // _this.scrollStops.push(0);
        for (var i = 0; i < items.length; i++) {
            curItem = items[i];
            // observer.observe(curItem);
            _this.scrollStops.push($(curItem).offset().top);
        }
        _this.scrollStopEnd = $(params.scrollArea).height() - $(curItem).height() - params.navHeight;
        console.log('scrollStops',_this.scrollStops,_this.scrollStopEnd);

        $(window).on('resize scroll', function() {
            _this.checkScroll($(this).scrollTop());
        });

    }, 
    checkScroll : function(scrollTop) {
        var _this = this;
        var currentScrollStop;
        var i;
        if (scrollTop > _this.scrollStopEnd) {
            // $("#expert-group-sidebar").css('top',_this.scrollStopEnd + 'px');
            $("#expert-group-sidebar").removeClass('fixed');
            return;
        } else {
            $("#expert-group-sidebar").addClass('fixed');
        }
        for (i = 0; i < _this.scrollStops.length; i++) {
            if (scrollTop < _this.scrollStops[i]) {
                currentScrollStop = i;
                break;
            }
        }
        if (currentScrollStop == undefined) currentScrollStop = i;
        if (currentScrollStop != _this.scrollStop) {
            _this.scrollStop = currentScrollStop;
            if (_this.scrollStop > 0) {
                console.log(_this.scrollStop,_this.scrollStops[_this.scrollStop-1]);
                var offset = 150 + 150 - _this.scrollStops[_this.scrollStop-1];
                $("#expert-group-sidebar").css('top', offset +'px');
            } else {
                $("#expert-group-sidebar").css('top','auto');
                // $("#expert-group-sidebar").css('position','fixed');
            }
        }
        //console.log(scrollTop,currentScrollStop);

    },

    getObserver : function(callback) {
        var _this = this;
        var options = {
          rootMargin: '100px 0px',
          threshold:0
        }

        var observer = new IntersectionObserver(callback, options);
        console.log('ExpertGroupsScroller.handleScroll',observer);
        return observer;
    },
    startReached: function(e) {
        console.log('ExpertGroupsScroller.startReached()',e);
    },
    endReached: function(e) {
    },
};

var ExpertGroupToggler = {
    
    toggler : '',
    expertGroups : '',
    moreExperts : '',
    moreExpertsInArticle : '',
    moreExpertsOnTop : '',
    isFixed : false,
    init : function(params) {
        console.log('ExpertGroupToggler.init()',params);
        var _this = this;
        _this.toggler = $(params.togglerSelector);
        _this.expertGroups = $(params.expertGroupsSelector);
        _this.moreExperts = $(params.moreExpertsSelector);

        _this.toggler.off('click');
        _this.toggler.on('click',function(e) {
            _this.toggle();
        });

        /* for now turn off, for easy testing */
        if (toGenericHelper.isMobileLayout()) {
            _this.handleMoreExpertMobile();
        }
    },
    toggle : function() {
        var _this = this;
        _this.toggler.toggleClass('open');
        _this.expertGroups.toggleClass('open');
        if (_this.toggler.hasClass('open')) {
            $('body').css('overflow','hidden');
        } else {
            $('body').css('overflow','auto');
        }
    },
    handleMoreExpertMobile : function() {
        var _this = this;

        if (_this.moreExperts.length > 0 && $("#blog-article").length > 0) {

            _this.setupMoreExpertsInArticle();

            $(window).on('resize', function() {                 
                _this.renderExpertMobileChange();
            });
            _this.renderExpertMobileChange();

            $(window).on('scroll', function() { 

                _this.renderExpertMobileTop();
            });
        }

    },
    setupMoreExpertsInArticle : function() {
        var _this = this;

        // if ($("#article .hero-users").length > 0) {
        //     _this.moreExpertsInArticle = $("#article .hero-users");
        //     console.log('hi');
        // } else {
            var minPees = 3;
            var offset = 10;
            var pees = $("#article .__content > p");
            var peePosition = pees.length > 3 ? Math.floor(pees.length /3 ) : (pees.length-1);
            $(pees[peePosition]).after('<div id="article-more-experts-in-article"></div>');
            _this.moreExpertsInArticle = $("#article-more-experts-in-article");
            _this.threshold = _this.moreExpertsInArticle.position().top + _this.moreExpertsInArticle.height() + offset; 
        // }
        $("#article").prepend('<div id="article-more-experts"></div>');
       _this.moreExpertsOnTop = $("#article-more-experts");
       _this.moreExpertsOnTop.html(_this.moreExperts.html());

    },
    renderExpertMobileChange : function() {
        var _this = this;
        if (toGenericHelper.isMobileLayout() && _this.moreExperts.html().length > 0) {
            var html = _this.moreExperts.html();
            _this.moreExperts.html('');
            _this.moreExpertsInArticle.html(html);
        } else if (!toGenericHelper.isMobileLayout() && _this.moreExperts.html().length == 0) {
            var html = _this.moreExpertsInArticle.html();
            _this.moreExpertsInArticle.html('');
            _this.moreExperts.html(html);
        } 
    },
    renderExpertMobileTop : function() {
        var _this = this;
        var offset = 10;
        
        if ( $(window).scrollTop() > _this.threshold) {
            _this.toggler.addClass('replaced');
            _this.moreExpertsOnTop.addClass('fixed_top');
        } else {
            _this.toggler.removeClass('replaced');
            _this.moreExpertsOnTop.removeClass('fixed_top');            
            
        }
    },
}

var ArticleBannerToggler = {
    
    toggler : '',
    expertGroups : '',
    articleBanner : '',
    articleBannerInArticle : '',
    articleBannerOnTop : '',
    isFixed : false,
    init : function(params) {
        console.log('ExpertGroupToggler.init()',params);
        var _this = this;
        _this.toggler = $(params.togglerSelector);
        _this.expertGroups = $(params.expertGroupsSelector);
        _this.articleBanner = $(params.articleBannerSelector);

        
        /* for now turn off, for easy testing */
        if (toGenericHelper.isMobileLayout()) {
            _this.handleArticleBannerMobile();
        }
    },
   
    handleArticleBannerMobile : function() {
        var _this = this;

        if (_this.articleBanner.length > 0 && $("#blog-article").length > 0) {

            _this.setupArticleBanner();

            $(window).on('scroll', function() { 

                _this.renderArticleBannerMobileTop();
            });
        }

    },
    setupArticleBanner : function() {
        var _this = this;

        $("#article").prepend('<div id="article-banner-mobile"></div>');
        _this.articleBannerOnTop = $("#article-banner-mobile");
        // _this.articleBannerInArticle = $("#article-banner-mobile");
        _this.articleBannerOnTop.html(_this.articleBanner.html());
        _this.threshold = _this.articleBannerOnTop.position().top + _this.articleBannerOnTop.height() - 120;
        console.log(_this.threshold);
        $("#article-banner-mobile").css("height", $("#article-banner-mobile").height());
        _this.articleBanner.html('');

    },
   
    renderArticleBannerMobileTop : function() {
        var _this = this;
        var offset = 10;
        
        if ( $(window).scrollTop() > _this.threshold) {
            _this.articleBannerOnTop.addClass('fixed_top');
        } else {
            _this.articleBannerOnTop.removeClass('fixed_top');            
            
        }
    },
}

/*
 * ============================= CTOCustomButtonTracking - tracking for app buttons =============================
 */
var CTOCustomButtonTracking = {
    init: function (params) {
        var _this = this;
        this._appDownloadSelector = params.appDownloadSelector;
        $(function () {
            $(_this._appDownloadSelector).on("click", function () {
                _this.track(this);
            });
        });
    },
    track: function (buttonElement) {
        var category = "Lead";
        var eventName = "visit_app_store";
        var label = "";
        // could check buttonElement href to make sure it's all good
        if (buttonElement && buttonElement.href) {
            if (buttonElement.href.includes("apple")) {
                label = "app_store:ios";
            } else if (buttonElement.href.includes("google")) {
                label = "app_store:android";
            }
        }
        if (typeof fbq === "function") {
            fbq("track", category);
        }
        if (typeof ga === "function") {
            ga("send", "event", category, eventName, label);
        }
    },
};
CTOCustomButtonTracking.init({ appDownloadSelector: "[data-track=app_download]" });

/*
 * ============================= toAutocompleteHandler - Autocomplete related functions =============================
 */
var toAutocompleteHandler = {
    init: function (params) {
        //console.log("toAutocompleteHandler.init()", params);
        var _this = this;
        this.latLngHolderId = "location_latlng";
        this.addressHolderId = "location_address";
        this.inputsToInit = [];
        $(document).ready(function () {
            _this.initDomReady();
        });
    },
    initDomReady: function () {
        this.setDataHolderElements();
    },
    setDataHolderElements: function () {
        this.latLngHolderElement = $("#" + this.latLngHolderId);
        this.addressHolderElement = $("#" + this.addressHolderId);
    },
    initAutocomplete: function () {
        var map = toGenericHelper.getGoogleMap();
        //console.log("toAutocompleteHandler.initAutocomplete()", map);
        if (map) {
            if ($("#app-layout.settings-myprofile #location-search")) {
                this.addAutocomplete("#app-layout.settings-myprofile #location-search", "settings-myprofile-map", map);
            }
        } else {
            /*
            if ($("#app-layout.guide #location-search")) {
                this.addAutocompleteWithoutMap("#app-layout.guide #location-search", "location");
            }
            if ($("#app-layout.traveler #location-search")) {
                this.addAutocompleteWithoutMap("#app-layout.traveler #location-search", "location");
            }
            */
        }
    },
    addAutocomplete: function (inputfield, pacContainerClass, theMap) {
        //console.log("addAutocomplete called", inputfield);
        var autocomplete = this.setupAutocomplete(inputfield, pacContainerClass);
        if (!autocomplete) {
            return
        };
        // only if there is a map, listen to the changes in the search to reflect it in the map
        var myMap = toGenericHelper.getGoogleMap(theMap);
        if (!myMap) {
            return
        };
        var myMapDiv = myMap.getDiv();
        // note: no need to check for toMapHandler here, if not present then myMap is null already
        var latLngHolder = toMapHandler.getHolderOfMap(this.latLngHolderId, myMapDiv);
        var addressHolder = toMapHandler.getHolderOfMap(this.addressHolderId, myMapDiv);
        //a bit messy, but it works for now
        autocomplete.addListener("place_changed", function() {                
            var place = autocomplete.getPlace();
            if ($(inputfield).hasClass("to-map")) {                
                var url = '';
                url += toGlobalStateContainer.ROOT + "/" + theone.session.content_locale + "/experts?";
                url += 'sr=1&search_by=location&';
                url += 'criteria[bounds]=' + place.geometry.viewport.toUrlValue() + '&';
                window.location.href=url;
            } else if ($(myMapDiv).hasClass("with-user-position")) {
                if (toMapHandler.focusMapOnLocation(place, myMap)) {
                    if ($(myMapDiv).hasClass("with-user-position")) {
                        toMapMarkerHandler.clearLocationPickerMarker();
                        toMapMarkerHandler.addLocationPickerMarker(place.geometry.location,addressHolder, latLngHolder, myMap);
                    }
                }
            }
        });
    },
    initAutocompleteForInput: function (inputSelector, inputId) { // TODO: using lots of globals now
        if (toGenericHelper.gmLoaded) {
            this.addAutocompleteWithoutMap(inputSelector, inputId);
            //console.log("init input autocomplete " , inputId);
        } else {
            //console.log("Could not init input autocomplete", inputId);
            this.inputsToInit.push( { selector: inputSelector, id: inputId } );
        }
    },
    addAutocompleteWithoutMap: function (inputfield, pacContainerClass) {
        var _this = this;
        var autocomplete = this.setupAutocomplete(inputfield, pacContainerClass);
        if (!autocomplete) {
            return;
        }
        //console.log("adding place changed only");
        // update location_latlng without map
        // a bit messy, but it works for now
        autocomplete.addListener("place_changed", function() {
            var place = autocomplete.getPlace();
            if (place.geometry) {
                console.log("place changed", place.geometry.location);
                _this.latLngHolderElement.val(place.geometry.location.toUrlValue());
            } else {
                console.log("ERROR: place changed but no geometry", place);
            }
        });
    },
    checkInputsToInit: function () {
        if (toGenericHelper.gmLoaded) {
            for (var i in this.inputsToInit) {
                var inputData = this.inputsToInit[i];
                this.addAutocompleteWithoutMap(inputData.selector, inputData.id);
                console.log("delayed input autocomplete init ", inputData.id);
            }
        }
    },
    resetContainerClass: function (pacContainerClass) {
        $(".pac-container")
            .removeClass("main")
            .removeClass("home-map")
            .removeClass("home-banner")
            .removeClass("guide-banner")
            .removeClass("traveler-banner")
            .addClass(pacContainerClass);
    },
    selectFirstOnEnter: function(input) {
        // store the original event binding function
        var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
        function addEventListenerWrapper(type, listener) {
            // simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
            // and then trigger the original listener
            if (type === "keydown") {
                var orig_listener = listener;
                listener = function (event) {
                    var suggestion_selected = $(".pac-item-selected").length > 0;
                    if (event.which === 13 && !suggestion_selected) {
                        var simulated_downarrow = $.Event("keydown", {keyCode:40, which:40});
                        orig_listener.apply(input, [simulated_downarrow]);
                    }
                    orig_listener.apply(input, [event]);
                };
            }
            _addEventListener.apply(input, [type, listener]); // add the modified listener
        }
        if (input.addEventListener) {
            input.addEventListener = addEventListenerWrapper;
        } else if (input.attachEvent) {
            input.attachEvent = addEventListenerWrapper;
        }
    },
    setupAutocomplete: function (inputfield, pacContainerClass) {
        var _this = this;
        if (typeof $(inputfield).attr("id") === "undefined") {
            return;
        }
        console.log("setupAutocomplete ", $(inputfield).attr("id"), pacContainerClass);
        $(inputfield).on("focus", function() {
           _this.resetContainerClass(pacContainerClass);
        });
        // https://developers.google.com/maps/documentation/javascript/places-autocomplete
        // { types: ["(cities)"] } https://developers.google.com/places/supported_types#table3
        // maybe we can use "address" ? or maybe "address" for define user location, "(regions)" for place request?
        var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById($(inputfield).attr("id")),
            {  }
        );
        _this.selectFirstOnEnter(inputfield);
        return autocomplete;
    },
};
toAutocompleteHandler.init(); // no need to be in document ready

/*
 * ============================= searchHandler - all search related parts together =============================
 */
var toSearchHandler = {
    init: function (params) {
        var _this = this;
        setTimeout(function() {
            _this.delayedInit(params);
        }, 1000);
    },
    delayedInit: function(params) {
        this.setConstantValues(params);
        this.setElements();
        this.setServices();
        this.setMobileToggle();
        //console.log("toSearchHandler.init()", params, this.searchInputs);
        // ...
        // this.makeSmartSearch(...)
        for (var i = 0; i < this.searchInputs.length; i++) {
            this.makeSmartSearch(this.searchInputs[i].element, this.searchInputs[i].resultsElementID);
        }
    },
    setMobileToggle: function() {
        var _this = this;
        //console.log("setMobileToggle");
        $("#theone-search,.navbar-form .form-group.nav-bar-search-wrapper").on('click',function(e) {
            _this.showMobileSearchBox();
        });
    },
    setConstantValues: function (params) {
        // more like props, not constants
        this._searchInputSelector = params.searchInputSelector;
        this._resultsSelected = false;
        this._config = {
            people: "es",
            articles: "es",
            places: false,
            tags: false,
            expertises: false,
            expert_groups: true,
        };
    },
    setElements: function () {
        var inputElements = $(this._searchInputSelector);
        //console.log("setElements", this._searchInputSelector, inputElements);
        this.searchInputs = [];
        for (var i = 0; i < inputElements.length; i++) {
            this.searchInputs.push({
                element: $(inputElements[i]),
                resultsElementID: "to-search-results-" + i,
            });
        }
    },
    setServices: function () {
        this.service = new google.maps.places.AutocompleteService();
    },
    showMobileSearchBox: function () { // TODO CHECK - phase out?
        if ($("nav").css("height") != "44px") {
            return; // If not mobile dont do anything
        }
        $(".nav-bar-search-wrapper input").val("");
        $(".nav-bar-search-wrapper input").css("display", "block");
        $(".navbar-brand").addClass("mobile-search-open");
        $(".nav-bar-search-wrapper").addClass("mobile-search-open");
        $(".nav-bar-search-wrapper input").focus();
    },
    hideMobileSearchBox: function () { // TODO CHECK - phase out?
        if ($("nav").css("height") != "44px") {
            return; // If not mobile dont do anything
        }
        if ($(".nav-bar-search-wrapper input").val().length > 0){
            $(".nav-bar-search-wrapper input").val("");
            $(".nav-bar-search-wrapper input").focus();
            return;
        }
        $(".navbar-brand").removeClass("mobile-search-open");
        $(".nav-bar-search-wrapper").removeClass("mobile-search-open");
        $(".nav-bar-search-wrapper input").css("display", "none");
        $(".nav-bar-search-wrapper input").blur();
    },
    getResultsHTML: function (containerID, searchInput) {
        //var filter = searchInput ? searchInput.data("filter") : null;
        // NOTE: see more info about filter in processSearch()
        var resultsHTML = '';
        resultsHTML += '<div id="' + containerID +'" class="smartsearch" style="display:none">';
        resultsHTML += '<ul class="list-unstyled">';
        if (this._config.expert_groups) {
            resultsHTML += '<li class="expert_groups">';
            resultsHTML += '  <p class="group-title people">' + toGlobalStateContainer.SEARCH.texts.expertgroups + '</p>';
            resultsHTML += '  <ul class="list-unstyled results"></ul>';
            resultsHTML += '</li>';
        }
        if (this._config.tags) {
            resultsHTML += '<li class="tags">';
            resultsHTML += '  <p class="group-title tags">' + toGlobalStateContainer.SEARCH.texts.tags + '</p>';
            resultsHTML += '  <ul class="list-unstyled results"></ul>';
            resultsHTML += '</li>';
        }
        if (this._config.expertises) {
            resultsHTML += '<li class="expertise">';
            resultsHTML += '  <p class="group-title expertise">' + toGlobalStateContainer.SEARCH.texts.expertise + '</p>';
            resultsHTML += '  <ul class="list-unstyled results"></ul>';
            resultsHTML += '</li>';
        }
        if (this._config.articles) {
            resultsHTML += '<li class="articles">';
            resultsHTML += '  <p class="group-title article">' + toGlobalStateContainer.SEARCH.texts.articles + '</p>';
            resultsHTML += '  <ul class="list-unstyled results"></ul>';
            resultsHTML += '</li>';
        }
        if (this._config.people) {
            resultsHTML += '<li class="people">';
            resultsHTML += '  <p class="group-title people">' + toGlobalStateContainer.SEARCH.texts.people + '</p>';
            resultsHTML += '  <ul class="list-unstyled results"></ul>';
            resultsHTML += '</li>';
        }
        if (this._config.places) { // this was if (!filter) {} before _config was introduced
            resultsHTML += '<li class="places">';
            resultsHTML += '  <p class="group-title places">' + toGlobalStateContainer.SEARCH.texts.places + '</p>';
            resultsHTML += '  <ul class="list-unstyled results"></ul>';
            resultsHTML += '</li>';
        }
        resultsHTML += '</ul>';
        resultsHTML += '</div>';
        return resultsHTML;
    },
    makeSmartSearch: function(searchInput, resultsElementID) {
        //console.log("makeSmartSearch", searchInput, resultsElementID);
        var _this = this;
        searchInput.attr("autocomplete", "off");
        searchInput.after(this.getResultsHTML(resultsElementID, searchInput));
        // add listeners
        searchInput.on("keydown", function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        });
        searchInput.on("keyup", function (e) {
            if (searchInput.val() == undefined || searchInput.val() == "") {
                _this.locationSuggestions([], google.maps.places.PlacesServiceStatus.OK, resultsElementID);
                _this.peopleSuggestions([], resultsElementID);
                _this.expertGroupSuggestions([], resultsElementID);
                _this.expertiseSuggestions([], resultsElementID);
                _this.tagSuggestions([], resultsElementID);
                _this.articleSuggestions([], resultsElementID);
                _this.hideSmartSearchOnNoResults(resultsElementID);
            } else {
                if (_this.searchTimeoutID) {
                    clearTimeout(_this.searchTimeoutID);
                }
                _this.searchTimeoutID = setTimeout(function() {
                    _this.processSearch(searchInput.val(), resultsElementID, searchInput);
                }, 400);
            }
        });
        searchInput.on("focus", function (e) {
            if ($(this).val() != undefined && $(this).val() != "") {
                _this.processSearch($(this).val(), resultsElementID, this);
            }
        });

        $("#" + resultsElementID).hover(
            function () { _this._resultsSelected = true; },
            function () { _this._resultsSelected = false; }
        );
        searchInput.blur(function () {
            if (!_this._resultsSelected) { //if you click on anything other than the results
                $("#" + resultsElementID).hide(); //hide the results
            }
        });
    },
    hideSmartSearchOnNoResults: function (resultsElementID) {
        if ($("#" + resultsElementID + " .group-title:visible").length == 0) {
            $("#" + resultsElementID).hide();
        } else {
            $("#" + resultsElementID).show();
        }
    },
    resetResultGroup: function (resultsElementID, resultClass, resultCount) {
        $("#" + resultsElementID + " ." + resultClass + " ul").html("");
        if (resultCount) {
            $("#" + resultsElementID).show();
            $("#" + resultsElementID + " ." + resultClass + " .group-title").show();
        } else {
            if (resultClass === "expert_groups") {
                // don't hide it, userSuggestions will add a line in this class
            } else if (resultClass === "people") {
                // also hide expert_groups if this is all empty
                $("#" + resultsElementID + " ." + resultClass + " .group-title").hide();
                $("#" + resultsElementID + " .expert_groups .group-title").hide();
                //this.hideSmartSearchOnNoResults(resultsElementID);
            } else {
                $("#" + resultsElementID + " ." + resultClass + " .group-title").hide();
                //this.hideSmartSearchOnNoResults(resultsElementID);
            }
        }
    },
    locationSuggestions: function (predictions, status, resultsElementID) {
        if (status != google.maps.places.PlacesServiceStatus.OK) {
            logger(status);
        }
        if (predictions == undefined) predictions = [];
        this.resetResultGroup(resultsElementID, "places", predictions.length);
        predictions.forEach(function (prediction) {
            var resultText = prediction.structured_formatting.main_text;
            var resultSubmit = "toSearchHandler.submitLocation(this, '" + prediction.description + "', '" + prediction.place_id + "')";
            if (prediction.structured_formatting.secondary_text) {
                resultText += ' <span class="unimportant">' + prediction.structured_formatting.secondary_text + '</span>';
            }
            $("#" + resultsElementID + " .places ul").append('<li onclick="' + resultSubmit + '">' + resultText + '</li>');
        });
    },
    peopleSuggestions: function(predictions, resultsElementID) {
        this.resetResultGroup(resultsElementID, "people", predictions.length);
        predictions.forEach(function(prediction) {
            if (prediction) {
                var resultSubmit = "toSearchHandler.submitPerson(this, '" + prediction.description + "', '" + prediction.id + "','" + prediction.lat + "', '" + prediction.lng + "', '" + prediction.slug + "')";
                var resultText = prediction.description;
                resultText += ' <span class="unimportant">' + prediction.profession + '</span>';
                if (prediction.expert_certificates && prediction.expert_certificates.length) {
                    // for now we just add the first even if the user has more
                    resultText += '<img src="' + prediction.expert_certificates[0].badge + '" style="padding-left: 20px; height: 20px;">';
                }
                $("#" + resultsElementID + " .people ul").append('<li onclick="' + resultSubmit + '">' + resultText + '</li>');
            }
        });
    },
    userSuggestions: function (predictions, resultsElementID) {
        this.resetResultGroup(resultsElementID, "people", predictions.length);
        predictions.forEach(function (prediction) {
            if (prediction) {
                var resultSubmit = "toSearchHandler.submitPerson(this, '" + prediction.description + "', '" + prediction.id + "','" + prediction.lat + "', '" + prediction.lng + "', '" + prediction.slug + "')";
                var resultText = prediction.description;
                if (prediction.profession) {
                    resultText += ', <b>' + prediction.profession + '</b>';
                }
                if (prediction.location_description) {
                    resultText += ', ' + prediction.location_description;
                }

                if (prediction.url && prediction.category && prediction.category === "All") {
                    // this is the generic "all matching users" prediction
                    $("#" + resultsElementID + " .expert_groups ul").prepend('<li><a' + (prediction.nofollow  ? ' rel="nofollow"': '') + ' href="' + prediction.url + '">' + resultText + '</a></li>');
                } else if (prediction.url) {
                    $("#" + resultsElementID + " .people ul").append('<li><a' + (prediction.nofollow  ? ' rel="nofollow"': '') + ' href="' + prediction.url + '">' + resultText + '</a></li>');
                } else {
                    $("#" + resultsElementID + " .people ul").append('<li onclick="' + resultSubmit + '">' + resultText + '</li>');
                }
            }
        });
    },
    expertGroupSuggestions: function (predictions, resultsElementID) {
        this.resetResultGroup(resultsElementID, "expert_groups", predictions.length);
        // first put generic tag/ppl prediction
        // -> will be added from userSuggestions since that's where the data is ready
        // then matched expert groups
        predictions.forEach(function(prediction) {
            var resultSubmit, resultText;
            resultText = prediction.description;
            /*
            resultText += ' (' + prediction.count + ')';
            if (prediction.matched_tags) {
                resultText += ' <span class="unimportant">#' + prediction.matched_tags.join(" #") + '</span>';
            }
            */
            resultText += ' <span class="unimportant">&nbsp;' + prediction.count + '</span>';
            $("#" + resultsElementID + " .expert_groups ul").append('<li><a href="' + prediction.url + '">' + resultText + '</a></li>');
        });
    },
    expertiseSuggestions: function (predictions, resultsElementID) {
        this.resetResultGroup(resultsElementID, "expertise", predictions.length);
        predictions.forEach(function(prediction) {
            var resultSubmit, resultText;
            resultText = prediction.description;
            if (prediction.type === "expert_certificate") {
                resultSubmit = "toSearchHandler.submitExpertCert(this, '" + prediction.description + "', '" + prediction.id + "', '" + prediction.type + "')";
                resultText += '<img src="' + prediction.badge + '" style="padding-left: 20px; height: 20px;">';
                if (prediction.url) {
                    if (prediction.url.indexOf("?") > 0) {
                        prediction.url += "&sr=1";
                    } else {
                        prediction.url += "?sr=1";
                    }
                }
            } else {
                resultSubmit = "toSearchHandler.submitExpertise(this, '" + prediction.description + "', '" + prediction.id + "')";
            }
            if (prediction.url) {
                $("#" + resultsElementID + " .expertise ul").append('<li><a' + (prediction.nofollow  ? ' rel="nofollow"': '') + ' href="' + prediction.url + '">' + resultText + '</a></li>');
            } else {
                $("#" + resultsElementID + " .expertise ul").append('<li onclick="' + resultSubmit + '">' + resultText + '</li>');
            }
        });
    },
    articleSuggestions: function (predictions, resultsElementID) {
        this.resetResultGroup(resultsElementID, "articles", predictions.length);
        predictions.forEach(function(prediction) {
            var resultSubmit, resultText;
            resultText = prediction.description;
            $("#" + resultsElementID + " .articles ul").append('<li><a' + (prediction.nofollow  ? ' rel="nofollow"': '') + ' href="' + prediction.url + '">' + resultText + '</a></li>');
        });
    },
    tagSuggestions: function(predictions, resultsElementID) {
        this.resetResultGroup(resultsElementID, "tags", predictions.length);
        predictions.forEach(function(prediction) {
            var resultSubmit = "";
            var resultText = prediction.category + " / " + prediction.description;
            if (prediction.type === "expert_certificate") {
                resultSubmit = "toSearchHandler.submitExpertCert(this, '" + prediction.description + "', '" + prediction.id + "', '" + prediction.type + "')";
                if (prediction.badge) {
                    resultText += '<img src="' + prediction.badge + '" style="padding-left: 20px; height: 20px;">';
                }
            } else if (prediction.type === "expert_tag") {
                resultSubmit = "toSearchHandler.submitExpertTag(this, '" + prediction.description + "', '" + prediction.id + "', '" + prediction.type + "', '" + prediction.expert_certificate_id + "')";
                if (prediction.badge) {
                    resultText += '<img src="' + prediction.badge + '" style="padding-left: 20px; height: 20px;">';
                }
            } else {
                resultSubmit = "toSearchHandler.submitTag(this, '" + prediction.description + "', '" + prediction.id + "', '" + prediction.expertise_id + "')";
            }
            if (prediction.url) {
                $("#" + resultsElementID + " .tags ul").append('<li><a' + (prediction.nofollow  ? ' rel="nofollow"': '') + ' href="' + prediction.url + ( prediction.url.indexOf("?") <= 0 ? '?sr=1'  : '') + '">' + resultText + '</a></li>');
            } else {
                $("#" + resultsElementID + " .tags ul").append('<li onclick="' + resultSubmit + '">' + resultText + '</li>');
            }
        });
    },
    trackSearch: function (searchEvent, eventLabel) {
        var category = "Search";
        /*
        if (typeof fbq === "function") {
            fbq("track", category);
        }
        */
        if (typeof ga === "function") {
            ga("send", "event", category, searchEvent, eventLabel);
        }
    },
    processSearch: function (searchQuery, resultsElementID, inputElement) {
        var _this = this;
        var inputObject = { input: searchQuery };
        var filter = $(inputElement).data("filter");

        if (!searchQuery) {
            return;
        }

        var container = $(inputElement).closest(".search");
        if (container.hasClass("cta-search-container")) {
            //console.log("SEARCHED WITHIN CTA");
            toGenericHelper.trackCtaAction(container);
            //var ctaBoxId = container.closest(".cta_box").data("tid");
            //var eventLabel = "cta:" + ctaBoxId + ":" + searchQuery;
            this.trackSearch("search_cta", searchQuery);
        } else {
            this.trackSearch("search_general", searchQuery);
        }

        this._latestSearch = {
            query: searchQuery,
            total: 0,
            pending: Object.keys(this._config).filter(function (item) { return Boolean(_this._config[item]); }),
        };

        if (toGlobalStateContainer.SEARCH.selected_expert_certificate_id) {
            // this is a thing for when the site is embedded
            inputObject.expert_certificate_id = this.selected_expert_certificate_id;
            this.findPeople(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.peopleSuggestions(data, resultsElementID);
            });
            this.findTagsSearch(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.tagSuggestions(data, resultsElementID);
            });
            this.locationSuggestions([], google.maps.places.PlacesServiceStatus.OK, resultsElementID);
            this.expertiseSuggestions([], resultsElementID);
            this.expertGroupSuggestions([], resultsElementID);
            return;
        }

        // handling filter could be pretty complex (it could be a CSV value for example)
        // for now it's only used 1 place, and there the value is no-place

        if (this._config.people === "es") {
            this.findUsersSearch(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.userSuggestions(data, resultsElementID);
                _this.updateLatestSearch("people", data);
            });
        } else if (this._config.people === true) {
            /*
             // old way of getting user results
            this.findPeople(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.peopleSuggestions(data, resultsElementID);
                _this.updateLatestSearch("people", data);
            });
            /* - */
        }

        if (this._config.expert_groups) {
            this.findExpertGroups(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.expertGroupSuggestions(data, resultsElementID);
                _this.updateLatestSearch("expert_groups", data);
            });
        }

        if (this._config.expertises) {
            this.findExpertise(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.expertiseSuggestions(data, resultsElementID);
                _this.updateLatestSearch("expertises", data);
            });
        }

        if (this._config.articles === "es") {
            this.findArticlesEs(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.articleSuggestions(data, resultsElementID);
                _this.updateLatestSearch("articles", data);
            });
        } else if (this._config.articles === true) {
            /* // old way of getting article results
            this.findArticles(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.articleSuggestions(data, resultsElementID);
                _this.updateLatestSearch("articles", data);
            });
            */
        }

        if (this._config.tags) {
            this.findTagsSearch(inputObject, function (data) {
                if (!$(inputElement).is(":focus")) return;
                _this.tagSuggestions(data, resultsElementID);
                _this.updateLatestSearch("tags", data);
            });
        }

        if (filter) {
            // see filter notes above - don't search for location
            this._config.places = false;
        }
        if (this._config.places) {
            inputObject.types = ['(regions)'];
            this.service.getPlacePredictions(inputObject, function (predictions, status) {
                if (!$(inputElement).is(":focus")) return;
                _this.locationSuggestions(predictions, status, resultsElementID);
                _this.updateLatestSearch("places", predictions);
            });
        }
    },
    updateLatestSearch: function (key, data) {
        this._latestSearch.pending = this._latestSearch.pending.filter(function (item) { return (item != key); });
        this._latestSearch.total += data ? data.length : 0;
        if (this._latestSearch.pending.length === 0) {
            console.log("all search done", this._latestSearch);
        }
    },
    findSearchResults: function (searchObject, callBack, resultType) {
        var apis = {
            people:      toGlobalStateContainer.ROOT + "/api/find-people",
            expertise:   toGlobalStateContainer.ROOT + "/api/find-expertise",
            tags:        toGlobalStateContainer.ROOT + "/api/find-tags-search",
            articles:    toGlobalStateContainer.ROOT + "/api/find-articles",
            users_es:    toGlobalStateContainer.ROOT + "/api/find-users-es",
            articles_es: toGlobalStateContainer.ROOT + "/api/find-articles-es",
            expert_groups: toGlobalStateContainer.ROOT + "/api/find-expert-groups",
        };
        if (!resultType || !apis[resultType]) {
            console.log("findSearchResults - can not find results for", resultType);
            return;
        }
        $.ajax({
            contentType: "application/json",
            data: JSON.stringify(searchObject),
            dataType: "json",
            success: function(data) {
                if (data.success) {
                    callBack(data.data);
                } else {
                    logger(resultType + " ~ no success: " + data.error);
                }
            },
            error: function(){
                logger(resultType + " ERROR: connection");
            },
            processData: false,
            type: "POST",
            url: apis[resultType]
        });
    },
    findPeople: function (searchObject, callBack) {
        this.findSearchResults(searchObject, callBack, "people");
    },
    findArticles: function (searchObject, callBack) {
        searchObject.locale = Lang.locale();        
        this.findSearchResults(searchObject, callBack, "articles");
    },
    findArticlesEs: function (searchObject, callBack) {
        searchObject.locale = Lang.locale();        
        this.findSearchResults(searchObject, callBack, "articles_es");
    },
    findExpertGroups: function (searchObject, callBack) {
        searchObject.locale = Lang.locale();
        this.findSearchResults(searchObject, callBack, "expert_groups");
    },
    findExpertise: function (searchObject, callBack) {
        searchObject.locale = Lang.locale();
        this.findSearchResults(searchObject, callBack, "expertise");
    },
    findTagsSearch: function (searchObject, callBack) {
        searchObject.locale = Lang.locale();        
        this.findSearchResults(searchObject, callBack, "tags");
    },
    findUsersSearch: function (searchObject, callBack) {
        searchObject.locale = Lang.locale();        
        this.findSearchResults(searchObject, callBack, "users_es");
    },
    submitTag: function (element, tagString, tagId, expertiseId) {
        var form = $(element).closest("form");
        $(form).find("input[name='search']").val(tagString);
        $(form).find("input[name='type']").val("tag");
        $(form).find("input[name='id']").val(tagId + "_" + expertiseId);
        $(form).submit();
    },
    submitExpertise: function (element, expertiseString, expertiseId) {
        var form = $(element).closest("form");
        $(form).find("input[name='search']").val(expertiseString);
        $(form).find("input[name='type']").val("expertise");
        $(form).find("input[name='id']").val(expertiseId);
        $(form).submit();
    },
    submitExpertTag: function (element, query, itemId, itemType, certificateId) {
        var form = $(element).closest("form");
        $(form).find("input[name='search']").val(query);
        $(form).find("input[name='type']").val(itemType);
        //$(form).find("input[name='id']").val(itemId);
        $(form).find("input[name='id']").val(itemId + "_" + certificateId);
        $(form).submit();
    },
    submitExpertCert: function (element, query, itemId, itemType) {
        var form = $(element).closest("form");
        $(form).find("input[name='search']").val(query);
        $(form).find("input[name='type']").val(itemType);
        $(form).find("input[name='id']").val(itemId);
        $(form).submit();
    },
    submitPerson: function (element, personString, personId, lat, lng, slug) {
        var form = $(element).closest("form");
        var searchInput = $(form).find("input[name='search']");
        if (searchInput.data("submit")) {
            var handler = eval(searchInput.data("submit"));
            if (typeof handler === "function") {
                handler(personId, element);
                return;
            }
        }

        // Below makes the link from smart search go to the map
        /*
        $(form).find("input[name='search']").val(personString);
        $(form).find("input[name='type']").val('person');
        $(form).find("input[name='id']").val(personId);

        var circle = new google.maps.Circle({radius: 500, center: new google.maps.LatLng(lat, lng)});
        $(form).find("input[name='bounds']").val(circle.getBounds().toUrlValue());
        $(form).submit();*/

        // Below makes the link go directly to the profile page

        window.location.href = theone.url("/" + ((slug != undefined) ? slug : personId));
    },
    submitLocation: function (element, locationString, place_id) {
        var map = toGenericHelper.getGoogleMap();
        if (!map) {
            // need to create a Map obj, so the service can be created
            var tempDiv = $('<div style="display:none;"></div>').appendTo($(element));
            map = new google.maps.Map(tempDiv[0], {});
            // no need to set the map on toMapHandler since a redirect will follow anyway
            // (and better not do it since toMapHandler is not necessarily there)
            //toMapHandler.setMap(map);
        }
        var request = { placeId: place_id };
        var placesService = new google.maps.places.PlacesService(map);
        placesService.getDetails(request, function (place, status) {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
                var url = '';
                url += toGlobalStateContainer.ROOT + "/" + theone.session.content_locale + "/experts?";
                url += 'sr=1&search_by=location&';
                url += 'criteria[bounds]=' + place.geometry.viewport.toUrlValue() + '&';
                // url += 'criteria[place_id]=' + place.place_id + '&';
                url += 'show_map=1&';
                url += 'suggestion=' + place.formatted_address + '&';                
                window.location.href=url;
            } else {
                //if we couldn't find the location, search for the location as a string in our data
                var url = '';
                url += toGlobalStateContainer.ROOT + "/" + theone.session.content_locale + "/experts?";
                url += 'sr=1&search_by=any&';
                url += 'criteria[search]=' + locationString + '&';
                window.location.href=url;

            }

        });
    },
};
/* searchHandler (end) */

/* // never used (should also check the info window init...)
    function moveMapToFitInfoWindow(marker,map) {
        var point = fromLatLngToPoint(marker.getPosition(),map);
        var w = $("#map").width();
        var h = $("#map").height();
        var ww= 320;
        var wh = $('.gm-style-iw').height() + 90;
        console.log("("+w+","+h+") ("+ww+","+wh+") ("+point.x+","+point.y+")");
        var margin = 50;
        var topSpaceLeft = point.y - wh - margin;
        var bottomSpaceLeft = h - point.y - margin;
        var leftSpaceLeft = point.x - margin;
        var rightSpaceLeft = w - point.x - wh - margin;
        var moveY = 0;
        var moveX = 0;
        if (topSpaceLeft < 0 ) {
            moveY = topSpaceLeft;
        } else if (bottomSpaceLeft < 0 ) {
            moveY = -bottomSpaceLeft;
        }
        if (leftSpaceLeft < 0) {
            moveX = leftSpaceLeft;
        } else if (rightSpaceLeft < 0 ) {
            moveX = -rightSpaceLeft;
        }
        if (moveX != 0 || moveY != 0) {
            map.setCenterWithOffset(map.getCenter(),moveX,moveY);
        }
    }

    // ONLY used in moveMapToFitInfoWindow()
    function fromLatLngToPoint(latLng, map) {
        var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
        var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
        var scale = Math.pow(2, map.getZoom());
        var worldPoint = map.getProjection().fromLatLngToPoint(latLng);
        return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
    }
*/
