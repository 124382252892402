var toSmartJSLoader = {
    init: function () {
        this._domLoaded = false;
        this._winLoaded = false;
        this._postInits = {};
        this.initWindowLoad();
        this.initDOMReady();
        //console.log("toSmartJSLoader.init done");
    },
    initWindowLoad: function () {
        window.addEventListener("load", function () {
            toSmartJSLoader._winLoaded = true;
            toSmartJSLoader.loadDeferScripts();
        });
    },
    initDOMReady: function () {
        var _this = this;
        $(function () {
            _this._domLoaded = true;
            _this._appScriptElem = $("#script-app");
            //console.log("toSmartJSLoader._domLoaded done", _this._appScriptElem.length);
            if (!_this._appScriptElem.length) {
                console.log("ERROR app script tag not found");
                return;
            }
            _this.loadDeferScripts();
        });
    },
    getPostInitObj: function (key) {
        return this._postInits[key];
    },
    postInit: function (key, initObj) {
        this._postInits[key] = initObj;
    },
    loadDeferScripts: function () {
        if (this._domLoaded && this._appScriptElem && this._winLoaded) {
            //console.log("loadDeferScripts go");
            this.loadGoogleMapsApi();
            this.loadUserScripts();
            this.loadCookieConsent();
            this.loadFBPixel();
        }
    },
    insertLinkElement: function (path) {
        //console.log("insertLinkElement,", path);
        var existingLinks = document.getElementsByTagName("link");
        for (var i = 0; i < existingLinks.length; i++) {
            if (existingLinks[i].href === path) {
                //console.log("insertLinkElement - already linked:", path);
                return;
            }
        }
        var elem = document.createElement("link");
        var firstLinkElem = existingLinks[0];
        elem.rel = "stylesheet";
        elem.type = "text/css";
        elem.href = path;
        firstLinkElem.parentNode.insertBefore(elem, firstLinkElem);
    },
    insertScriptElement: function (path) {
        var elem = document.createElement("script");
        var firstScriptElem = document.getElementsByTagName("script")[0];
        elem.async = 1;
        elem.src = path;
        firstScriptElem.parentNode.insertBefore(elem, firstScriptElem);
    },
    insertScriptElementAndCall: function (path, callback) {
        var elem = document.createElement("script");
        var firstScriptElem = document.getElementsByTagName("script")[0];
        if (typeof callback === "function") {
            elem.onload = function () {
                callback();
            };
        }
        elem.async = 1;
        elem.src = path;
        firstScriptElem.parentNode.insertBefore(elem, firstScriptElem);
    },
    loadGoogleMapsApi: function () {
        var apiKey = this._appScriptElem.data("gmaps-api-key");
        if (apiKey) {
            var apiUrl = "https://maps.googleapis.com/maps/api/js?key=" + apiKey + "&libraries=places&callback=CTOGoogleIsLoaded";
            this.insertScriptElement(apiUrl);
        } else {
            console.log("ERROR gmaps api key not found");
        }
    },
    loadUserScripts: function () {
        //console.log("toSmartJSLoader.loadUserScripts");
        if (theone && theone.user) {
            // load the user
            var path = this._appScriptElem.data("path-user");
            if (path) {
                this.insertScriptElement(path);
            } else {
                console.log("ERROR user js path not found");
            }
        }
    },
    loadCookieConsent: function () {
        var domainurl = 'https://' + document.domain;
        if (domainurl == Config.get('app.app_url') && theone && theone.cookieConsentData) {
            var ccSrc = theone.cookieConsentData.src;
            var ccInit = theone.cookieConsentData.initObj;
            if (ccSrc && ccInit) {
                if (theone.cookieConsentData.cssSrc) {
                    this.insertLinkElement(theone.cookieConsentData.cssSrc);
                }
                this.insertScriptElementAndCall(ccSrc, function () {
                    if (window.cookieconsent) {
                        window.cookieconsent.initialise(ccInit);
                    }
                });
            }
        }
    },
    loadFBPixel: function () {
        function toAsyncFBInitDelayPromiseInner() {
            return new Promise(function (resolve) {
                setTimeout(resolve, 100);
            });
        };

        var _this = this;

        toAsyncFBInitDelayPromiseInner().then(function () {
            _this.insertScriptElement(theone.url("/js/fb-pixel.js"));
        }).catch(function () { console.error("FB init promise/async error"); });
    },
};
toSmartJSLoader.init();
